.me-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
}
.navbar-nav {
    padding-right: 0 !important;
}

.backgroundImageSection {
    padding: 100px 20px;
    color: #fff;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 1%;
}

.submit-button {
    margin-bottom: 1%;
}

.spacer {
    height: 30px;
}

.footer, .footer-link {
    color: white;
    text-decoration: none;
}

.bg-gray, .bg-grey {
    background-color: grey;
}

.padded {
    margin-top: 12%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 12%;
}

.padded-bottom {
    padding-bottom: 10%;
}

.padded-top {
    padding-top: 3% !important;
}

.invisible {
    visibility: hidden !important;
}

.center-div {
    position: absolute;
    right: 10%;
    bottom: 40%;
    left: 60%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: black;
    text-align: center;
}

.carousel-slide-header {
    font-size: x-large;
}

.image-center {
    margin: auto;
}

.side-padded {
    padding-left: 2%;
    padding-right: 2%;
}

.padded-home-grid-text, padded-home-grid-image {
    margin: 1%;
    padding: 1%;
}

.padded-home-grid-text {
    background-color: black;
    color: white;
}

.fit-height {
    object-fit: cover !important;
}